import React from "react"
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom"

export const Fail403 = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <h2>Error 403: Du är inte authoriserad att ta del av sidan</h2>
    </div>
  )
}
