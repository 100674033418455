import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
} from "react-router-dom";
import { Survey } from "./screens/Survey";
import { Login } from "./screens/Login";
import { Fail403 } from "./screens/403";
import { AddEmail } from "./screens/AddEmail";
import { PrivacyPolicy } from "./screens/PrivacyPolicy";
import { LoginLoaderScreen } from "./screens/LoginLoaderScreen";
import Info from "./screens/Info";
import Faqs from "./screens/Faqs";
import Instructions from "./screens/Instructions";
import Header from "./components/header/Header";
import InstructionItem from "./screens/InstructionItem";
const App = () => {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/info" component={Info} />
        <Route exact path="/faqs" component={Faqs} />
        <Route exact path="/instructions" component={Instructions} />
        <Route exact path="/instructions/:title" component={InstructionItem} />
        <Route exact path="/survey" component={Survey} />
        <Route exact path="/403" component={Fail403} />
        <Route exact path="/insert-email" component={AddEmail} />
        <Route exact path="/gdpr" component={PrivacyPolicy} />
        <Route
          exact
          // path="/login-loader/:orderRef/:name/:surname/:userSSN/:userSex"
          path="/login-loader"
          component={LoginLoaderScreen}
        />
        <Redirect to="/" />
      </Switch>
    </Router>
  );
};

export default App;
