import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Spacer } from "../components/Helpers";
import ClipLoader from "react-spinners/ClipLoader";
import BankIDLogo from "./../assets/images/bankid.svg";
import Titan from "./../assets/images/Transparent.svg";
import Header from "../components/header/Header";
import { isAndroid, isIOS, isTablet } from "react-device-detect"
import Cohere from "cohere-js";
import  amplitude from "amplitude-js"

Cohere.init("JWp1KYSiIolf3auqGCsUKQJs");

export const url = (slug?: string) =>
  process.env.NODE_ENV === "production"
    ? `https://titan-dm.herokuapp.com${slug ?? ""}`
    : `http://localhost:3000${slug ?? ""}`;
// : `http://localhost:3000/${slug ?? ""}`

// document.addEventListener("")

export const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};
export const method = "POST";

amplitude.getInstance().logEvent('LANDING_PAGE');

export const Login = () => {
  const history = useHistory();
  const [ssn, setSSN] = useState<string | null>(null);
  const [ssnFail, setSSNFail] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [tabletAndOpenOnThisDevice, setTabletAndOnThisDevice] = useState(true);

  const handleClick = async () => {
    // setSSNFail("Studien är full!")
    // return
    console.log('clickkk', )
    if(ssn?.length === 10) {
      setSSNFail("Ange 12 siffror")
      return;
    }
    setLoading(true)
    const authData = await fetch(url("/authenticate"), {
      headers,
      method,
      body: JSON.stringify({ ssn })
    })
    const res = await authData.json()
    console.log("res", res)
    setSSNFail(null)
    if (res.isProceed === "Age-failure") {
      setSSNFail("Felaktig ålder!")
      setLoading(false)
      return;
    }
    if (res.isProceed === "SSN-failure") {
      setSSNFail("Felaktikt personnummer!")
      setLoading(false)
      return;
    }
    if((isAndroid || isIOS) && !isTablet) history.push("/login-loader", { orderRef: res.orderRef })
    if(isTablet && tabletAndOpenOnThisDevice) {
      history.push("/login-loader", { orderRef: res.orderRef })
      return
    }
    // if (isAndroid) {
    //   window.location.href = "bankid:///?redirect=null"
    //   // window.location.href = `bankid:///?redirect=${window.location.href}login-loader?orderRef=${res.orderRef}`
    // }
    // if (isIOS)
    //   window.location.href = `bankid:///?redirect=${window.location.href}login-loader?orderRef=${res.orderRef}`
    // if (!isIOS && !isAndroid) {
    const collectData = await fetch(url("/collect"), {
        headers,
        method,
        body: JSON.stringify({ orderRef: res.orderRef })
      })
      console.log("collectData", collectData)
      const collectRes = await collectData.json() 
      const { userSSN, name, userSex, isProceed } = collectRes
      console.log('res old', res)
      console.log('collectRes', collectRes)
      localStorage.setItem("MYkey", `${ssn ?? "fnull"}`)
      if (isProceed === "SSN-failure") setSSNFail("Felaktikt personnummer!")
      if (isProceed === "Age-failure") setSSNFail("Felaktig ålder!")
      if (isProceed === true) {
        localStorage.setItem("key", `${ssn}`)
        history.push("/gdpr", {
          name,
          userSSN,
          userSex
        })
      }
      setLoading(false)
    // }
  };

  return (
    <Container >
      <BankIdWrapper>
        <div className="logo">
          <img src={Titan} alt="logo" />
        </div>

        <h3 style={{ fontFamily: FONT }}>
          <p>
        Välkommen till TITAN-DM. Rekryteringen av studiedeltagare är nu klar. Antalet deltagare som ingår i studien är uppnått och studien är därmed stängd för ytterligare inklusion. 
        </p>
        <p>
Du som redan är med i studien når oss via teknisk och medicinsk support som meddelats.
</p>
<p>
Vi ser fram emot att studien fullföljs och när den är klar kommer resultatet i form av publikationer och populärvetenskapliga sammanfattningar spridas. 
</p>
<p>
Vänligen,
<p>
Team TITAN-DM
</p>
</p>
        </h3>
        {/* <InputContainer>
          <Input
            autoFocus
            placeholder="YYYYMMDD-XXXX"
            onChange={(e) => {
              let input = e.target.value
              if(input.includes("-")) input = input.replace("-", "")
              setSSN(input)
            }}
            style={ssnFail ? { borderColor: "red" } : {}}
            onKeyPress={(key) => {
              console.log("key", key.code);
              if (key.code === "Enter") handleClick();
            }}
          />
          {/* <Spacer isHorizontal size={2} /> */}
          {/* {ssnFail && <h3 color="red">{ssnFail}</h3>} */}
        {/* </InputContainer> */} 
        <Spacer size={2} />
        {/* {loading ? (
          <>
          <ClipLoader loading={loading} size={50} />
          <div>(Öppna din mobil)</div>
          </>
        ) : (
          <>
          <Button onClick={handleClick}>
            <img src={BankIDLogo} style={{ width: 20, height: 20 }} />
            <Spacer isHorizontal size={1} />
            Logga in med BankID
          </Button>
       {isTablet && <> 
        <Spacer size={2} />
          <div> Du verkar använda en platta - vill du logga in med BankID på platten eller genom mobilen?</div>
          <button onClick={() => setTabletAndOnThisDevice(true)} style={tabletAndOpenOnThisDevice ? {backgroundColor: "green"}:{}}>Genom plattan</button>
          <button onClick={() => setTabletAndOnThisDevice(false)} style={!tabletAndOpenOnThisDevice ? {backgroundColor: "green"}:{}}>Genom mobilen</button> 
        </>}
          </>
        )} */}
      </BankIdWrapper>
    </Container>
  );
};

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const FONT =
  "-apple-system, system-ui, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, Helvetica Neue, Arial";

export const Button = styled.button`
  width: 300px;
  height: 70px;
  background-color: #155999;
  border-radius: 8px;
  border: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  font-family: ${FONT};
  &:hover {
    cursor: pointer;
    background-color: #0b3f70;
    box-shadow: 3px 5px 5px #9e9e9e;
  }
`;

// background-image: url("https://futurehealthpoland.com/img/slide-3-fp.jpg");

const Container = styled.div`
  background-image: url("/images/titan-bg-green.jpg");
  height: calc(100% - 60px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  margin: auto;
  //padding: 70px 20px 20px 20px;
`;

const BankIdWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 60px;
  border-radius: 25px;
  width: 100%;
  max-width: 1268px;
  .logo {
    max-width: 350px;
    max-height: 150px;
    img {
      width: 100%;
      height: 100%;
    }
  }
`;

const Input = styled.input`
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  font-family: ${FONT};
  text-align: center;
  font-size: 1rem;
`;
