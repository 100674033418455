import React from "react";
import {
  BGContainer,
  InfoContainer,
  InfoHeader,
  Title,
} from "../components/Helpers";
import "../assets/styles/info.css";
import { Link, Redirect, useParams } from "react-router-dom";
const InstructionItem = () => {
  const params = useParams<{ title: string }>();
  console.log({ params });
  const videos = [
    {
      title: "Att ta ett bröst-EKG med Coala_low res",
      url: "/videos/video1.mp4",
    },
    {
      title: "Att ta ett tum-EKG med Coala_low res",
      url: "/videos/video2.mp4",
    },
    {
      title: "Coala app measurement demo",
      url: "/videos/video3.mov",
    },
  ];
  const video = videos.find((v) => v.title === params.title);
  if (!params.title || !video) {
    return <Redirect to="/" />;
  }
  return (
    <BGContainer>
      <InfoContainer>
        <InfoHeader>
          <Link to="/instructions" className="btn btn-close me-3" />

          <Title>{video?.title}</Title>
        </InfoHeader>
        <video
          width="100%"
          height={600}
          className="video"
          controls
          src={video?.url}
        />
      </InfoContainer>
    </BGContainer>
  );
};

export default InstructionItem;
