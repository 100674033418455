import React from "react"
import { useEffect } from "react"
import { isAndroid, isIOS } from "react-device-detect"
import { useHistory } from "react-router-dom"
import { url } from "./Login"
import { headers, method } from "./Login"

// interface LoginLoaderArgs {}
export const LoginLoaderScreen = (orderRef: string) => {
  const history = useHistory()
  // const orderRef = new URL(window.location.href).searchParams.get("orderRef")

  const makeReq = async (orderRef: string) => {
    // if (isIOS) {window.location.href = `bankid:///?redirect=${window.location.href}login-loader?orderRef=${res.orderRef}`
    if (isIOS) {window.location.href = `bankid:///?redirect=${window.location.href}`}
    if (isAndroid) window.location.href = "bankid:///?redirect=null"

    const collectData = await fetch(url("/collect"), {
      headers,
      method,
      body: JSON.stringify({ orderRef })
    })
    
    console.log("collectData", collectData)
    const collectRes = await collectData.json()
    // alert("re-rendering")
    const { userSSN, name, userSex } = collectRes
    if (collectRes?.isProceed === true) {
      localStorage.setItem("key", `${userSSN}`)
      history.push("/gdpr", {
        name,
        userSSN,
        userSex
      })
    }
  }
  useEffect(() => {
    makeReq(orderRef)
  }, [])

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      Loggar in dig...
    </div>
  )
}
