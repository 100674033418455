import React, { useState } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import styled from "styled-components";
// @ts-ignore
import "@typeform/embed/build/css/widget.css"; // import necessary CSS
// @ts-ignore
import titan from "./../assets/images/titan1.png";
import Checkmark from "./../assets/images/checkmark1.png";
import {
  BGContainer,
  Heading,
  InfoContainer,
  InfoHeader,
  Spacer,
  Title,
} from "../components/Helpers";
import { Button } from "./Login";
import "../assets/styles/info.css";
export const PrivacyPolicy = () => {
  const [firstCheckBox, setFirstCheckBox] = useState(false);
  const [secondCheckBox, setSecondCheckBox] = useState(false);
  const history = useHistory();
  const location = useLocation();
  // @ts-ignore
  let { name, userSSN, userSex } = location?.state || {};
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery()
  if(query.get("name")){
    name = query.get("name");
    userSSN = query.get("userSSN");
    userSex = query.get("userSex");
  }

  console.log('name', name)
  console.log('userSSN', userSSN)
  console.log('userSex', userSex)
  


  const CheckBox = styled.div`
    border: 3px solid black;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
    }
  `;

  const Paragraph = styled.p`
    font-size: 19px;
  `;

  return (
    <>
      <BGContainer>
        <InfoContainer>
          <InfoHeader className="flex-column align-items-start">
            <div className="titan-logo-gdpr">
              <img src={titan} alt="titan-logo" />
            </div>

            <Title>
              Vi ansvarar för hanteringen av dina personuppgifter i TITAN-DM
              studien.
            </Title>
          </InfoHeader>
          <Heading>Information till forskningspersoner</Heading>
          <p>
            Vi vill fråga dig om du vill delta i ett forskningsprojekt. I det
            här dokumentet får du information om projektet och om vad det
            innebär att delta.
          </p>
          <Heading>
            {" "}
            Vad är det för ett projekt och varför vill ni att jag ska delta?
            Projektbeskrivning:
          </Heading>
          <p>
            {" "}
            Förmaksflimmer är en vanlig hjärtrytmrubbning och risken ökar med
            åldern. Symptomen vid förmaksflimmer varierar och ibland märks det
            inte alls. Vid förmaksflimmer finns det en ökad risk för
            proppbildning vilket kan leda till stroke. Hos personer som är 65 år
            eller mer och samtidigt har ytterligare riskfaktorer, exempelvis
            diabetes, rekommenderas blodförtunnande behandling. Syftet med
            studien är att undersöka förekomst av förmaksflimmer och symtom hos
            personer med diabetes som är 65 år eller äldre med bröst- och
            tum-EKG Coala Heart Monitor.
          </p>
          <p>
            Vi har fått dina kontaktuppgifter via Diabetesförbundets
            patientförening. Du kan vara aktuell för studien om du inte tidigare
            haft diagnostiserat förmaksflimmer eller förmaksfladder, är 65 år
            eller mer och har diabetes.
          </p>
          <p>
            {" "}
            Forskningshuvudman för projektet är Region Gävleborg. Med
            forskningshuvudman menas den organisation som är ansvarig för
            projektet. Ansökan är godkänd av Etikprövningsmyndigheten,
            diarienummer för prövningen hos Etikprövningsmyndigheten är
            2021-03323.
          </p>
          <Heading>Hur går projektet till?</Heading>
          <p>
            Efter samtycke genom BankID kommer du till webformulär om studien.
            Du kommer via en digital länk besvara frågor om din hälsa och det
            förväntas ta ungefär 15 minuter. Om du uppfyller kriterier för att
            vara med: inget känt förmaksflimmer eller förmaksfladder, är 65 år
            eller äldre och har diabetes kan du vara aktuell för studien.
          </p>
          <p>
            Om du däremot redan har blodförtunnande behandling (Eliquis,
            Lixiana, Pradaxa, Xarelto, Waran) har en pacemaker/ICD/inopererad
            hjärtmonitor kan du däremot inte vara med i studien. När
            webformuläret är ifyllt kommer en Coala Heart Monitor att skickas
            till dig med instruktioner. Därefter genomför du registreringar med
            både bröst- och tum-EKG morgon och kväll under 90 dagar. Varje
            EKG-registrering görs under 30 sekunder (totalt 1 minut). Denna typ
            av EKG-registrering görs inte annars systematiskt i rutinsjukvård
            för att hitta förmaksflimmer.
          </p>
          <Heading>Möjliga följder och risker med att delta i studien</Heading>
          <p>
            I händelse av ett påvisat förmaksflimmer kommer din ordinarie
            vårdgivare att ta ställning till insättning av blodförtunnande
            behandling. På gruppnivå är detta ett effektivt sätt att förebygga
            stroke, men i det enskilda fallet kan blödningskomplikationer
            inträffa. Den sammanvägda bedömningen och eventuellt insättande av
            behandling görs därför genom din ordinarie vårdgivare. Om
            förmaksflimmer påvisas kontaktar vi dig och säkerställer att
            informationen når den vårdgivare du önskar.
          </p>
          <Heading>Vad händer med mina uppgifter?</Heading>
          <p>
            Projektet kommer att samla in och registrera information om dig. Du
            kommer kunna besvara frågor om din hälsa via ett digitalt formulär.
            Samtliga studiedeltagares uppgifter sammanställs och beskrivs
            vetenskapligt på ett sätt som gör att informationen inte kopplas
            till enskild person. Dina svar och dina resultat kommer att
            behandlas så att inte obehöriga kan ta del av dem. Ansvarig för dina
            personuppgifter är Region Gävleborg. Enligt EU:s
            dataskyddsförordning har du rätt att kostnadsfritt få ta del av de
            uppgifter om dig som hanteras i projektet, och vid behov få
            eventuella fel rättade. Du kan också begära att uppgifter om dig
            raderas samt att behandlingen av dina personuppgifter begränsas.
            Rätten till radering och till begränsning av behandling av
            personuppgifter gäller dock inte när uppgifterna är nödvändiga för
            den aktuella forskningen. Om du vill ta del av uppgifterna ska du
            kontakta huvudansvarig forskare Peter Magnusson (tfn: 026-154000, VO
            Kardiologi, Lasarettsvägen 1, 803 87, Gävle). Dataskyddsombud nås på
            dataskyddsombud@regiongavleborg.se, alternativt via växeln på 3
            026-15 40 00. Om du är missnöjd med hur dina personuppgifter
            behandlas har du rätt att ge in klagomål till
            Integritetsskyddsmyndigheten, som är tillsynsmyndighet.
          </p>
          <Heading>Hur får jag information om resultatet av studien?</Heading>
          <p>
            Studiens resultat kommer att publiceras i vetenskapliga tidskrifter,
            men också i form av svenska sammanfattningar. Om du vill ta del av
            resultaten kan vi skicka det till din e-post. Ange det i så fall i
            frågeformuläret.
          </p>
          <Heading>Försäkring och ersättning</Heading>
          <p>
            Du är försäkrad när du deltar i studien via patientförsäkringen. Det
            kostar inget att vara med i studien, men det utgår heller ingen
            ersättning.
          </p>
          <Heading>Deltagandet är frivilligt</Heading>
          <p>
            Ditt deltagande är frivilligt och du kan när som helst välja att
            avbryta deltagandet. Om du väljer att inte delta eller vill avbryta
            ditt deltagande behöver du inte uppge varför och det kommer inte
            heller att påverka din framtida vård eller behandling. Om du vill
            avbryta ditt deltagande ska du kontakta de ansvariga för projektet
            (se nedan). Kontakta oss gärna för frågor kring studien.
          </p>
          <Heading>Projektansvarig:</Heading>
          <p>
            Michel Tagliati, leg läkare E-post: michel.tagliati@proaktivhalsa.se
            Tfn: 076-312 46 35
          </p>
          <Heading>Huvudansvarig forskare:</Heading>
          <p>
            Peter Magnusson, överläkare, med dr, VO Kardiologi, Region
            Gävleborg, 801 87 Gävle E-post: peter.magnusson@regiongavleborg.se
            Tfn: 026-15 40 00
          </p>
          <Heading>
            Samtycke till att delta i studien (görs via Bank-ID)
          </Heading>
          <p>
            Jag har fått skriftlig informationen om studien och har haft
            möjlighet att ställa frågor. Jag får behålla den skriftliga
            informationen.
          </p>
          <p>
            Du måste godkänna båda punkterna nedan för att kunna komma vidare
            till studien.
          </p>
          <div className="d-flex align-items-start mb-2">
            <div>
              <CheckBox onClick={() => setFirstCheckBox((prev) => !prev)}>
                {firstCheckBox && <img src={Checkmark} alt="check" />}
              </CheckBox>
            </div>
            <Spacer isHorizontal size={2} />
            <Heading style={{ fontWeight: 400 }}>
              Jag samtycker till att delta i projektet ”Förekomst av
              förmaksflimmer med tum-EKG hos personer över 65 år med diabetes
              (TITAN-DM)”.
            </Heading>
          </div>
          <div className="d-flex align-items-start">
            <div>
              <CheckBox
                // style={secondCheckBox ? { backgroundColor: "gray" } : {}}
                // style={{ width: isMobile ? "52px" : "19px" }}
                onClick={() => setSecondCheckBox((prev) => !prev)}
              >
                {secondCheckBox && <img src={Checkmark} alt="check" />}
              </CheckBox>
            </div>
            <Spacer isHorizontal size={2} />
            <Heading style={{ fontWeight: 400 }}>
              Jag samtycker till att uppgifter om mig behandlas på det sätt som
              beskrivs i forskningspersoninformationen.
            </Heading>
          </div>
          {/* {firstCheckBox && secondCheckBox && ( */}
          <Spacer size={2} />
          <Button
            style={{
              width: "auto",
              height: "auto",
              padding: "10px 30px",
              ...(!firstCheckBox || !secondCheckBox
                ? { backgroundColor: "gray" }
                : {}),
            }}
            onClick={() => {
              if (firstCheckBox && secondCheckBox) {
                history.push("survey", {
                  name,
                  userSSN,
                  userSex,
                });
              }
            }}
          >
            Gå vidare till studien
          </Button>
        </InfoContainer>
      </BGContainer>
      <div className="container" style={{ padding: 20, maxWidth: "98%" }}>
      </div>
    </>
  );
};
