import React, { useEffect, useRef } from "react"
import { useLocation,useHistory } from "react-router-dom"
// @ts-ignore
import { createWidget } from "@typeform/embed"
import "@typeform/embed/build/css/widget.css" // import necessary CSS

export const Survey = () => {
  const container = useRef()
  const location = useLocation()
  const history = useHistory()
  // @ts-ignore
  const { name, userSSN, userSex } = location?.state
  if(!name || !userSSN || !userSex) history.push("403")

  // if (localStorage.getItem("key") !== userSSN) history.push("403")
  useEffect(() => {
    createWidget("NhD8JNIK", {
      // @ts-ignore
      container: container.current,
      hidden: {
        first_name: name,
        social_sec_number: userSSN,
        gender: userSex,
      },
      onSubmit: e => {
        console.log("e submit", e)
      },
      onQuestionChanged: e => {
        console.log("e changed", e)
      }
    })
  }, [])
  // @ts-ignore
  return (
    // @ts-ignore
    <div style={{ width: "100vw", height: "100vh" }} ref={container} />
  )
}

// export const Survey = () => {
//   return (
//     <ReactTypeformEmbed url="https://lw2hwmwy2c7.typeform.com/to/NhD8JNIK" />
//   )
// }
