import React from "react";
import {
  BGContainer,
  Heading,
  InfoContainer,
  InfoHeader,
  Title,
} from "../components/Helpers";
import "../assets/styles/info.css";
import Picture2 from "../assets/images/Picture2.png";
import Picture3 from "../assets/images/Picture3.png";
import Picture4 from "../assets/images/Picture4.png";
import Picture5 from "../assets/images/Picture5.png";
import Picture6 from "../assets/images/Picture6.jpg";
import Picture7 from "../assets/images/Picture7.jpg";
import Picture8 from "../assets/images/Picture8.png";

const Faqs = () => {
  return (
    <BGContainer>
      <InfoContainer>
        <InfoHeader>
          <Title>Vanliga frågor och svar Coala Heart Monitor</Title>
        </InfoHeader>
        <Heading>Fråga: Hur delar jag data med min vårdgivare?</Heading>
        <p>
          Svar: För att kunna dela data med din vårdgivare behöver du själv ta
          kontakt med vårdgivaren. Därefter kan du enkelt visa upp berörda EKG:n
          genom att ladda ner dem i PDF-format direkt i Coala-appen. Du kan
          välja om du vill visa upp mätningarna direkt i telefonen eller genom
          att exempelvis skriva ut dem.
        </p>
        <p>
          För att ladda ner dina mätningar i PDF-format, klickar du på den lilla
          dokumentsymbolen uppe i högra hörnet, se bild nedan:
        </p>
        <div className="graph-image-container">
          <img src={Picture2} alt="graph-picture-2" />
        </div>
        <p>Här ser du ett exempel på hur ett EKG i PDF-format visas:</p>
        <div className="graph-image-container">
          <img src={Picture3} alt="graph-picture-3" />
        </div>
        <Heading>
          Fråga: Varför visar analysresultat ”normala värden” men i PDF:en ser
          jag avvikande värden?
        </Heading>
        <p>
          Svar: För att appen ska visa ett avvikande värde måste både bröst- och
          tum-EKG vara avvikande. Detta är underbyggt av säkra algoritmer och
          syftar till att minimera antalet falska indikationer på
          förmaksflimmer. Enstaka avvikande bröst eller tum mätningar kan bero
          på brus eller andra yttre störningar.
        </p>
        <p>
          Coalan kan reagera på störningar på grund av kroppsrörelser, till
          exempel att man inte sitter helt stilla eller pratar samtidigt som man
          gör mätningen.
        </p>
        <p>
          I de fall man får ett enstaka avvikande värde på tum eller
          bröstmätningen, råder vi användare att göra om mätningen.
        </p>
        <p>
          Tänk på att vara avslappnad inför din Coalamätning. Håll Coalan med
          ett lätt men stabilt tryck genom hela mätningen. Du bör alltid sitta
          ner och helst även vila underarmarna mot en bordsskiva eller knät för
          att få mätningar med god EKG-kvalitet.
        </p>
        <p>
          I det fall det kvarstår frågetecken eller en oro kring resultatet av
          mätningen ber vi dig att kontakta din vårdgivare. Med fördel kan du då
          visa sparade PDF för din läkare som har möjlighet och kompetens att
          analysera och bedöma EKG-kurvan manuellt. Vid alla medicinska frågor
          ber vi att få hänvisa till din vårdgivare.
        </p>
        <Heading>Fråga: Hur nollställer jag Coalan?</Heading>
        <Heading>
          Svar: ”För att nollställa din Coala, följ stegen nedan:”
        </Heading>
        <p>För att nollställa din Coala, följ stegen nedan:</p>
        <ol>
          <li>
            Gå till Bluetooth-inställningar på din telefon och "Glöm enheten".
          </li>
          <li>
            Nollställ Coalan genom att sätta den i laddstationen. Håll ner på/av
            knappen i 8 sekunder. (Viktigt att koppla laddstationen till ett
            vägguttag, annars genomförs ej nollställningen.)
            <div className="graph-image-container">
              <img src={Picture4} alt="graph-picture-4" />
            </div>
          </li>
          <li>
            Ta ur Coalan ur laddstationen, den ska då blinka gult/grönt snabbt.
            Coalan är då i parningsläge, vilket betyder att den är redo att
            ansluta till Bluetooth.
            <div className="graph-image-container">
              <img src={Picture5} alt="graph-picture-5" />
            </div>
          </li>
          <li>
            Kontrollera att Bluetooth är påslaget på din telefon och logga in i
            Coala-appen.
          </li>
          <li>
            Klicka på "Anslut Coala" och följ sedan instruktionerna i appen för
            att åter ansluta Coalan.
          </li>
        </ol>
        <Heading>
          Fråga: Hur ställer jag in EKG-påminnelser i Coala-appen?
        </Heading>
        <p>Svar: </p>
        <p>
          Så här gör du för att ställa in EKG-påminnelser direkt i Coala appen:
        </p>
        <ol>
          <li>Logga in i Coala-appen</li>
          <li>Klicka på "Min Coala"</li>
          <li>Skrolla ner till "Påminnelser"</li>
          <li>
            Klicka sedan på plus-tecknet uppe i högra hörnet för att välja den
            tid du vill bli påmind
          </li>
          <li>Välj även de dagar i veckan du vill att påminnelsen upprepas</li>
        </ol>
        <p>(Enbart möjligt för iPhone telefoner)</p>
        <div className="graph-image-container">
          <img src={Picture6} className="border" alt="graph-picture-6" />
        </div>
        <Heading>
          Fråga: Jag vill veta mer om det avvikande värde som Coalan indikerar-
          hur gör jag då?
        </Heading>
        <p>
          Vid ett avvikande värde kan du klicka direkt på avvikelsen för att få
          mer information om vad den innebär, se bild nedan.
        </p>
        <div className="graph-image-container">
          <img src={Picture7} alt="graph-picture-7" />
        </div>
        <Heading>
          Tips: Visste du att du kan föra anteckningar till ditt EKG?
        </Heading>
        <p>
          I anslutning till en utförd mätning ombeds du att fylla i hur du mådde
          vid mättillfället.
        </p>
        <p>
          Väljer du alternativet "Annat, vänligen ange" får du möjligheten att
          själv skriva hur du mådde och vad du kan tycka är relevant för just
          denna mätning. Svaret kommer synas i anslutning till ditt EKG i din
          journal.
        </p>
        <div className="graph-image-container">
          <img src={Picture8} alt="graph-picture-8" />
        </div>
      </InfoContainer>
    </BGContainer>
  );
};

export default Faqs;
