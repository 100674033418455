import styled from "styled-components";

export const Spacer = ({
  size,
  isHorizontal,
}: {
  size: number;
  isHorizontal?: boolean;
}) => (
  <div style={isHorizontal ? { width: size * 10 } : { height: size * 10 }} />
);
export const Title = styled.h4`
  color: #1B9D4F;
`;
export const Heading = styled.h6`
  font-weight: 700;
`;
export const BGContainer = styled.div`
  padding: 30px;
  background-color: #f9f9f9;
`;
export const InfoContainer = styled.div`
  padding: 30px;
  border-radius: 15px;
  margin-bottom: 30px;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.08);
`;

export const InfoHeader = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #d2ddf3;
  margin-bottom: 20px;
`;
export const waiting = (seconds: number): Promise<string> =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve("timer klar");
    }, seconds * 1000);
  });
