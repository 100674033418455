import React from "react";
import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import MenuIcon from "../svgIcons/MenuIcon";
import InfoIcon from "../svgIcons/InfoIcon";
import InstructionsIcon from "../svgIcons/InstructionsIcon";
import FaqsIcon from "../svgIcons/FaqsIcon";
import TitanLogo from "../../assets/images/Transparent.png";
const Header = () => {
  return (
    <StyledHeader>
      <Dropdown>
        <Dropdown.Toggle className="menu-toggle carrot-none">
        <MenuIcon size={28} />
        <div style={{width: 5}}/>
          <span className="menu-text">Meny</span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="menu-dropdown">
          <Dropdown.Item as={Link} to="/info">
            <InfoIcon size={18} />
            <span className="ms-2"> Infoblad Coala Heart Monitor</span>
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/faqs">
            <InstructionsIcon size={18} />
            <span className="ms-2"> Vanliga frågor och svar</span>
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/instructions">
            <FaqsIcon size={18} />
            <span className="ms-2"> Instruktionsfilmer</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Link to={"/"} className="header-brand">
        <img src={TitanLogo} alt="titan-logo" />
      </Link>
    </StyledHeader>
  );
};
const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  .menu-toggle {
    background-color: transparent !important;
    display: flex;
    align-items: center;
    border: none !important;
    font-size: 12px;
    padding: 10px;
    font-weight: 600;
    &:active,
    &:focus {
      box-shadow: none !important;
    }
    &:hover {
      color: #1b9d4f;
      svg {
        fill: currentColor;
      }
    }
    &:focus {
      outline: none;
    }
    border-radius: 10px;
  }
  .carrot-none {
    &:after {
      content: none !important;
    }
  }
  .menu-dropdown {
    border: 0 !important;
    padding: 0.25rem 1.5rem !important;
    border-radius: 10px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    .dropdown-item {
      padding: 0.75rem 0 !important;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
      &:active {
        background-color: #fff !important;
        border-color: #fff !important;
      }
      &:focus {
        box-shadow: none !important;
      }
      &:hover {
        background-color: #fff !important;
        color: #1b9d4f;
        svg {
          fill: currentColor;
        }
      }
    }
  }
  .header-brand {
    width: 200px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .menu-text {
    color: #1b9d4f;
    margin-right: 10px;
    font-size: 16px;
    font-weight: 600;
  }
`;

export default Header;
