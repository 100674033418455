import React, { useState } from "react"
import { Spacer } from "../components/Helpers"

export const AddEmail = () => {
  const [input, setInput] = useState<string>("")
  console.log("input", input)
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        flexDirection: "row"
      }}
    >
      <div>
        <h2>Ange din email nedan för att bli kontaktad om framtida studier!</h2>
        <Spacer size={1} />
        <div>
          <input
            placeholder="Din email"
            value={input}
            onChange={t => setInput(t.target.value)}
          ></input>
          <button onClick={() => setInput("")}>Skicka</button>
        </div>
      </div>
    </div>
  )
}
