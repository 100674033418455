import React from "react";
import {
  BGContainer,
  InfoContainer,
  InfoHeader,
  Title,
} from "../components/Helpers";
import "../assets/styles/info.css";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
const Instructions = () => {
  const videos = [
    {
      title: "Att ta ett bröst-EKG med Coala_low res",
      url: "/videos/video1.mp4",
    },
    {
      title: "Att ta ett tum-EKG med Coala_low res",
      url: "/videos/video2.mp4",
    },
    {
      title: "Coala app measurement demo",
      url: "/videos/video3.mov",
    },
  ];
  return (
    <BGContainer>
      <InfoContainer>
        <InfoHeader>
          <Title>Instruktionsfilmer</Title>
        </InfoHeader>
        <div className="row justify-content-evenly">
          {videos.map((item) => (
            <div className="col-12 col-sm-6 col-lg-3" key={item.title}>
              <Card className="video-card">
                <video
                  width="100%"
                  height={250}
                  className="video"
                  // controls
                  src={item.url}
                />
                <Card.Body>
                  <Card.Title
                    as={Link}
                    className="video-title"
                    to={`/instructions/${item.title}`}
                  >
                    {item.title}
                  </Card.Title>
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>
      </InfoContainer>
    </BGContainer>
  );
};

export default Instructions;
