import React from "react";
import styled from "styled-components";
import {
  BGContainer,
  Heading,
  InfoContainer,
  InfoHeader,
  Title,
} from "../components/Helpers";
import "../assets/styles/info.css";
import Picture1 from "../assets/images/Picture1.png";
import Picture9 from "../assets/images/Picture9.png";
import { Table } from "react-bootstrap";
import TableImg from "../assets/images/table.png";

const Info = () => {
  return (
    <BGContainer>
      <InfoContainer>
        <InfoHeader>
          <Title>
            Informationsblad om Coala Heart Monitor i TITAN-DM studien
          </Title>
        </InfoHeader>
        <p>
          Du får detta kuvert och informationsblad då du anmält dig till att
          delta i TITAN-DM studien (
          <a href="https://www.titan-dm.se" target="_blank">
            www.titan-dm.se
          </a>
          )
        </p>
        <p>
          I detta kuvert finner du följande: Coala Heart Monitor, laddstation,
          USB-laddsladd, detta informationsblad, användarmanual Coala Heart
          Monitor och PostNord S returkuvert.
        </p>
        <p>
          OBS laddningsadapter medföljer ej. Använd exempelvis samma adapter som
          ingår till din smartphone.{" "}
        </p>
        <Heading>Vad är Coala Heart Monitor och vad mäter den?</Heading>
        <p>
          Coala Heart Monitor är en digital hjärtmonitor som ansluts via
          Bluetooth till din smartphone. En registrering utförs genom en mätning
          på bröstet och ytterligare en via tummarna. Instruktioner får du via
          din smartphone som visar hur detta går till.
        </p>
        <p>
          Enheten registrerar hjärtats elektriska signaler och analyserar dessa.
          Ett EKG presenteras sedan i din smartphone tillsammans med en analys
          av din hjärtrytm.
        </p>
        <p>
          Via din smartphone och Coala Appen startar du en registrering och via
          ’Min Journal’ ser du sedan dina resultat. I och med ditt deltagande i
          studien är du ombedd att registrera bröst- och tum EKG morgon och
          kväll samt vid symptom i 90 dagar.
        </p>
        <Heading>Kom i gång med Coala Heart Monitor</Heading>
        <ol>
          <li>
            Ta först ur Coalan ur laddningsstationen genom att trycka lätt på
            texten ’COALA’ med tummen på enhetens framsida. Vinkla och lyft
            försiktigt enhetens front först enligt bilden nedan. Använd inte en
            för stor kraft och försök inte att lyfta enheten rakt ur
            laddningsstationen.
            <div className="graph-image-container">
              <img src={Picture9} alt="graph-picture-9" />
            </div>
          </li>
          <li>
            Gå till inställningar på din smartphone och se till att aktivera
            Bluetooth.
            <ol type="a">
              <li>
                Se till att Bluetooth inte är ansluten till någon annan enhet
                när du försöker koppla Coalan till din smartphone.
              </li>
            </ol>
          </li>
          <li>
            Starta Coala Heart Monitor genom en snabb tryckning på startknappen.
            Den ska nu blinka grönt i snabb följd, cirka tre gånger per sekund.
          </li>
          <li>
            Ladda ner Coala App till din smartphone, där du vanligtvis laddar
            ned andra appar (Google Play till Android eller App Store till
            iPhone). Sök på ’Coalalife’.
          </li>
          <li>
            Logga in med den e-postadress du angav vid studieregistreringen samt
            följande lösenord: Titan-dm123 (notera versalen, symbolen och
            siffrorna).
          </li>
          <li>
            Följ instruktionerna i appen där du uppmanas att fylla i
            hälsouppgifter.
          </li>
          <li>
            Tryck på ‘Anslut min Coala nu’ om du vill ansluta direkt, annars
            vänta och tryck på ‘Anslut Coala’ vid ett senare tillfälle.
          </li>
          <li>
            När Coalan ansluts blir den stora cirkeln blå. Det indikerar att din
            smartphone och Coala är parkopplade. Se bild nedan.
          </li>
        </ol>
        <div className="graph-image-container">
          <img src={Picture1} alt="graph-picture-1" />
        </div>
        <p>
          Byt gärna lösenordet till ditt Coala-App konto genom något av nedan
          alternativ:
        </p>
        <ol type="a">
          <li>
            via ’Jag har glömt mina uppgifter’ i Coala Appens inloggningssida.
          </li>
          <li>
            besöka{" "}
            <a href="https://www.coalalife.com" target="_blank">
              www.coalalife.com
            </a>{" "}
            ’Mina Sidor’, logga in och följa anvisningarna för att nollställa
            lösenordet.
          </li>
        </ol>
        <Heading>Instruktioner för mätning</Heading>
        <ol>
          <li>
            Starta Coala Heart Monitor genom en snabb knapptryckning på
            startknappen. Din Coala ska nu blinka grönt, cirka en gång per
            sekund.
          </li>
          <li>
            Koppla av och sitt ned någon minut innan du startar en ny mätning
            för att försäkra dig om att du har uppnått din vilopuls. Det är
            viktigt för att få noggranna analysresultat.
          </li>
          <li>
            Öppna Coala Appen och tryck på ‘Starta’ för att starta en ny
            mätning.
          </li>
          <li>
            Håll Coala Heart Monitor stadigt mot bröstkorgen enligt bild i Coala
            Appen. Tryck på hjärtknappen på Coala Heart Monitor för att starta
            mätningen.
          </li>
          <li>
            Coala Heart Monitor kontrollerar först om det är tillräcklig
            hudkontakt och startar sedan en 30 sekunders bröstmätning. Andas
            lugnt, sitt ned, koppla av och prata inte under mätning.
          </li>
          <li>
            Efter bröstmätningen kommer en 30 sekunder tum-mätning. Placera
            tummarna på elektroderna enligt bilden i Coala Appen. Vila
            underarmarna mot ett bord eller i ditt knä för att få ett så
            optimalt resultat som möjligt.
          </li>
          <li>
            När mätningen är klar markerar du hur du kände dig innan mätningen.
            Välj ‘Annat, vänligen ange’ för att skriva fritext.
          </li>
          <li>Mätningen analyseras.</li>
          <li>Analysen är klar och kan ses under ’Min Journal’.</li>
          <ol type="a">
            <li>Vid normala resultat visas en blå cirkel.</li>
            <li>Vid avvikande resultat visas en rosa cirkel.</li>
            <li>
              Notera att analysresultatet i appen är från den kombinerade
              analysen av bröst- och tum-mätningen.
            </li>
          </ol>
          <li>
            En fullladdad Coala Heart Monitor räcker till minst 40 mätningar.
          </li>
        </ol>
        <Heading>Kategori- och resultatbeskrivning</Heading>
        <p>
          Systemet detekterar hjärtrytmavvikelser baserat på både bröst- och
          tum-mätning av EKG och delar in dem i kategorier, se tabell nedan.
        </p>
        <div className="table-container">
          <img src={TableImg} alt="table-1" />
          {/* <Table>
            <thead>
              <tr>
                <th>Kategori</th>
                <th>Beskrivning</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-blue">1</td>
                <td>Dina värden ser normala ut.</td>
              </tr>
              <tr>
                <td className="td-pink">2</td>
                <td>Coala indikerar tecken på förmaksflimmer.</td>
              </tr>
              <tr>
                <td className="td-pink">3</td>
                <td>Coala indikerar överhoppade hjärtslag.</td>
              </tr>
              <tr>
                <td className="td-pink">4</td>
                <td>Coala indikerar förhöjd hjärtfrekvens.</td>
              </tr>
              <tr>
                <td className="td-pink">5</td>
                <td>Coala indikerar tecken på snabbt ändrad hjärtrytm.</td>
              </tr>
              <tr>
                <td className="td-pink">6</td>
                <td>Coala indikerar förekomst av extraslag.</td>
              </tr>
              <tr>
                <td className="td-pink">7</td>
                <td>Coala indikerar tecken på återkommande extraslag.</td>
              </tr>
              <tr>
                <td className="td-blue">8</td>
                <td>Coala indikerar oregelbunden hjärtrytm.</td>
              </tr>
            </tbody>
          </Table> */}
          <div style={{ height: 20 }} />
        </div>
        <Heading>Resultat av EKG i TITAN-DM studien</Heading>
        <p>
          Projektansvarig läkare granskar alla avvikande EKG registreringar
          under studiens gång. Vid kliniska fynd kommer du att bli kontaktad av
          projektansvarig läkare. Återkoppling kring övriga EKG registreringar
          kommer inte att utges i denna studie.
        </p>
        <Heading>Vid studiens slut</Heading>
        <p>
          Efter 90 dagars registrering med Coala Heart Monitor är studien
          avslutad för din del. Använd det förfrankerade PostNord S kuvertet och
          skicka tillbaka följande delar: Coala Heart Monitor, laddstation och
          USB-laddsladd. Lägg gärna en lapp med för- och efternamn i kuvertet så
          ser vi att just du har returnerat din enhet. Returadress: Coala Life,
          Kålsängsgränd 10b, 753 19 Uppsala.
        </p>
        {/* <p>
          Om du tappar bort det förfrankerade kuvertet får du själv stå för
          fraktkostnaden vid returnering av Coala Heart Monitor. Returadress:
          Coala Life, Kålsängsgränd 10b, 753 19 Uppsala.
        </p>
        <p>
          Om du tappar bort eller råkar ha sönder en Coala Heart Monitor blir du
          ersättningsskyldig, 500 SEK.
        </p> */}
        <Heading>Teknisk support rörande Coala Heart Monitor</Heading>
        <p>
          Frågor som rör hur du genomför en mätning, inloggningsuppgifter samt
          övriga frågor kring Coala Heart Monitor kontakta:
        </p>
        <p>
          E-post:{" "}
          <a href="mailto:titan-dm@coalalife.com">titan-dm@coalalife.com</a>,
          tfn: 072-377 79 50, öppettider 9–16 helgfria vardagar.
        </p>
        <p>
          Coala Heart Monitor är en medicinteknisk produkt så hantera den med
          omtanke.
        </p>
        <Heading>Medicinsk support rörande Coala Heart Monitor</Heading>
        <p>
          Frågor som rör studien, studiedeltagande, resultat från EKG/ Coala
          Heart Monitor och övriga medicinska frågor kopplat till studien:
        </p>
        <p>Michel Tagliati, leg läkare</p>
        <p>
          E-post:{" "}
          <a href="mailto:michel.tagliati@proaktivhalsa.se">
            michel.tagliati@proaktivhalsa.se
          </a>
          , tfn: 076-312 46 35, öppettider 9–16 helgfria vardagar.
        </p>
        <Heading>Läs mer</Heading>
        <ul>
          <li>
            För mer information om Coala Heart Monitor, Vanliga frågor och svar,
            instruktionsfilmer besök{" "}
            <a href="www.titan-dm.se"> www.titan-dm.se</a>
          </li>
          <li>
            Läs den fullständiga användarmanualen som medföljer i kuvertet för
            mer information.
          </li>
        </ul>
      </InfoContainer>
    </BGContainer>
  );
};

export default Info;
